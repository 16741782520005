import './ThankYou.scss'

import { Link } from 'gatsby'
import React from 'react'

const ThankYou = () => {
  return (
    <div className="c-donate-thank-you">
      <header className="c-donate-thank-you__hero">
        <div className="c-donate-thank-you__content">
          <h1 className="c-donate-thank-you__heading">Thank you!</h1>

          <p className="c-donate-thank-you__copy">
            Your donation will empower young people in more than 160 countries
            to learn about computing and digital making.
          </p>

          <Link
            className="sc-rp-button c-donate-thank-you__button"
            to="/support-us/"
          >
            More ways to support us
          </Link>
        </div>
      </header>
    </div>
  )
}

export default ThankYou
