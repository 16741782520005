import ApplicationLayout from '@/Layouts/Application'
import MissionCta from '@/components/shared/MissionCta'
import PageMeta from '@/components/Application/PageMeta'
import React from 'react'
import ThankYou from '@/components/Pages/Donate/ThankYou'
import opengraphImageSrc from '@/pages/donate/images/opengraph.png'

const ThankYouPage = () => {
  const title = 'Thank you for donating'
  const description =
    'Through your donation, you give young people the opportunity to change their world using technology.'

  return (
    <ApplicationLayout>
      <PageMeta
        description={description}
        image={opengraphImageSrc}
        title={title}
      />

      <ThankYou />
      <MissionCta eventCategory="Donate: Thank you" />
    </ApplicationLayout>
  )
}

export default ThankYouPage
